export const useSessiontate = () => {
  return useState<object>('sessionState', () => {
    return {}
  })
}
export const useAuthorizationState = () => {
  return useState<object>('authorizationState', () => {
    return {}
  })
}

export default function useKlarna() {
  const session = useSessiontate()
  const authorization = useAuthorizationState()

  const implementation = {
    doAuthorize: async (
      klarnaObject: object,
      {
        onApprove: onApproveCallback,
        onError: onErrorCallback,
      }: { onApprove: Function; onError: Function }
    ) => {
      console.debug('Klarna doAuthorize....')
      try {
        const klarnaRawObject = toRaw(klarnaObject)

        const result = await new Promise((resolve, reject) => {
          let result: object
          Klarna.Payments.authorize(
            {
              payment_method_category: 'pay_later',
            },
            klarnaRawObject,
            function (res: object) {
              if (res.approved) {
                resolve(res)
              }

              if (res.show_form) {
                // approved = false, show_form = true --> This means the customer has terminated a required interaction in the widget, such as authentication or sign-up flows.
                if (!res.error) {
                  reject(createError({ message: 'CUSTOMER_ABORT' }))
                }

                const invalid_fields = []
                res.error.invalid_fields.forEach((field) => {
                  switch (field) {
                    case field.billing_address.street_address:
                      invalid_fields.push('INVALID_ADDRESS')
                      break
                    case field.billing_address.city:
                      invalid_fields.push('INVALID_CITY')
                      break
                    case field.billing_address.given_name:
                      invalid_fields.push('INVALID_NAME')
                      break
                    case field.billing_address.postal_code:
                      result.error.invalid_fields.push('INVALID_POSTCODE')
                      break
                    case field.billing_address.family_name:
                      invalid_fields.push('INVALID_LASTNAME')
                      break
                    case field.billing_address.email:
                      invalid_fields.push('INVALID_EMAIL')
                      break
                    default:
                      break
                  }
                })
                reject(
                  createError({
                    message: 'INVALID_DATA',
                    data: { invalid_fields: invalid_fields },
                  })
                )
              }
              // approved = false, show_form = false
              // If the response is show_form: false, the purchase is declined. You should hide the widget, and the user might select another payment method.
              reject(createError({ message: 'TRANSACTION_DECLINED' }))
            }
          )
        })
        onApproveCallback(result)
      } catch (error) {
        onErrorCallback(error)
      }
    },
  }

  const setKlarnaSession = (klarnaSession) => {
    session.value = klarnaSession
  }

  const setKlarnaAuthorization = (klarnaAuthorize) => {
    authorization.value = klarnaAuthorize
  }

  return {
    implementation,
    setKlarnaSession,
    setKlarnaAuthorization,
  }
}
